import React, { Component } from "react";
import "./Educations.css";
import { degrees } from "../../portfolio";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";

import { Fade } from "react-reveal";

class Educations extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="educations">
        <div className="educations-header-div">
          <Fade bottom duration={2000} distance="20px">

          </Fade>
        </div>
        <div className="educations-body-div">
          {degrees.degrees.map((degree) => {
            return <DegreeCard degree={degree} theme={theme} />;
          })}
        </div> 


      </div>
    );
  }
}

export default Educations;

// pout this under  Fade when you recieved
/*<h1 className="educations-header" style={{ color: theme.text }}>
 Degrees Received
</h1> */

// import { degrees } from "../../portfolio";
// import DegreeCard from "../../components/degreeCard/DegreeCard.js";

/*   <div className="educations-body-div">
          {degrees.degrees.map((degree) => {
            return <DegreeCard degree={degree} theme={theme} />;
          })}
        </div> */


