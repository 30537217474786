import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

export default function Footer(props) {
  // Define the handleClick function
  const handleClick = (event) => {
    event.preventDefault();
    window.location.href = 'https://obdl-community-join.netlify.app';
  };

  return (
    <div className="footer">
      <Fade>
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-left">
              <div className="footer-logo">
                <img src="/icons/favicon-96x96.png" alt="Logo" />
              </div>
              <div className="footer-text">
                <h3>We are committed to providing the best service.</h3>
              </div>

              <div className="footer-nav-container">
                <nav className="footer-nav">
                  <Link to="/home">Home</Link>
                  <Link to="/education">Education</Link>
                  <Link to="/experience">Experience</Link>
                  <Link to="/projects">Projects</Link>
                  <Link to="/contact">Contact</Link>
                </nav>
              </div>

              <div className="footer-social">
                <a
                  href="https://linkedin.com"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://facebook.com"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://twitter.com"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://youtube.com/@obdl-club"
                  className="social-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
            <div className="footer-right">
              <p>Let's join together and rebuild our world</p>
              <form className="subscribe-form">
                <input type="email" name="email" placeholder="Enter your email" />
                <button type="button" onClick={handleClick}>Subscribe</button>
              </form>
            </div>
          </div>
          <div className="footer-legal">
            © 2024 OBDL Corp. All Rights Reserved.<br />
            <a href="https://drive.google.com/file/d/1y4ErVxDrvykPr30LUiJri0xSh5MM_UEO/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
            <a href="https://drive.google.com/file/d/1i2UKMDaG7vCIHL84kR-mpxsEJsv27xeZ/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </div>
        </footer>
      </Fade>
    </div>
  );
}

// extra navigation if needed. and add it inside <div className="footer-nav-container">
/*  <nav className="footer-nav">
                  <Link to="/"></Link>
                  <Link to="/"></Link>
                  <Link to="/"></Link>
                  <Link to="/"></Link>
                  <Link to="/"></Link>
                </nav> */
              
